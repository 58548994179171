import React from 'react'
import { graphql } from 'gatsby'

import Home from '../assets/img/home.jpg'

import {
  HomeSlider,
  HomeServices,
  HomeAbout,
  HomeBrands,
  HomeNewsLetter,
  HomeLocalProducts
} from '../components'

import SEO from '../components/seo'

const schemaOrg = `{
  "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Services Irrigation",
  "provider": {
    "@type": "LocalBusiness",
    "name": "Irriglobe Irrigation",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Montréal",
      "addressRegion": "QC",
      "streetAddress": "8220 Pascal-Gagnon"
    },
    "description": "Installation de système d'irrigation. Installation de système d'arrosage automatique. Vente et réparation. Irriglobe, le spécialiste en irrigation à Montréal, Laval, Rive-Nord, Laurentides et toute la province de Québec!",
    "telephone": "514-905-6000",
    "image": "https://www.irriglobe.com/img/logo.png"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Montreal"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laval"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Rive-Nord"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laurentides"
  },
  "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Services Irrigation",
    "itemListElement": [
      {
        "@type": "OfferCatalog",
        "name": "Types services irrigation",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation résidentielle"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation commerciale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation municipale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation Toîts vert"
            }
          }
        ]
      }
    ]
  }
}`

class HomeIndex extends React.Component {

  render() {

    const seoData = {
      title: "Système d'irrigation et arrosage automatique",
      description:
        "Irriglobe, le spécialiste en irrigation à Montréal, Laval, Rive-Nord, Laurentides et toute la province de Québec! Installation de système d'irrigation. Installation de système d'arrosage automatique. Vente et réparation.",
      cover: Home,
      path: '',
      schemaOrg
    }

    return (
      <>
        <SEO seoData={seoData} />
        <HomeSlider />
        <HomeServices />
        <HomeAbout />
        <HomeBrands />
        <HomeNewsLetter />
        <HomeLocalProducts />
      </>
    )
  }
}

export default HomeIndex

export const homePageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
  }
`
